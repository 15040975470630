// import "./bootstrap";
// import "../scss/main.css";


import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/inertia-vue3";
// import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";


// createInertiaApp({
//     resolve: name => require(`./Pages/${name}.vue`),
//     setup({ el, app, props, plugin }) {
//         createApp({ render: () => h(app, props) })
//             .use(plugin)
//             .mount(el);
//     },
// });

createInertiaApp({
    // id: 'my-app',
    // resolve: name => {
    //     const pages =
    //         import.meta.glob('./Pages/**/*.vue', { eager: true })
    //     return pages[`./Pages/${name}.vue`]
    // },
    // Webpack
    resolve: name => require(`./Pages/${name}`),
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .mount(el)
    },
})